













































import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';
import {defineComponent} from '@vue/composition-api';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaPreLab2',
  components: {AiLoadingOverlay, STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part1a: null,
        part1b: null,
        part1c: null,
        language: 'en',
      },
      questions: [
        {
          en: 'a) Enthalpy of dissolution of the ionic compound $\\ce{X+Y-}$',
          fr: 'a) L’enthalpie de mise en solution du sel $\\ce{X+Y-}$',
          inputName: 'part1a',
        },
        {
          en: 'b) Lattice energy of the ionic compound $\\ce{X+Y-}$',
          fr: 'b) L’énergie réticulaire du sel $\\ce{X+Y-}$',
          inputName: 'part1b',
        },
        {
          en: 'c) Enthalpy of hydration of the gaseous ion $\\ce{Z+ (g)}$',
          fr: 'c) L’énergie d’hydratation de l’ion gazeux $\\ce{Z+ (g)}$',
          inputName: 'part1c',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
